.background-color {
  background: linear-gradient(120deg, #ebf5f3, #eaf9fa);
}
.background-image {
  background-image: url("./assets/BackgroundLogoSpeaker.svg");
  background-repeat: no-repeat;
  background-position: right;
  background-size: inherit;
}
.container-wrapper {
  padding: 0 240px;
}
.outer-container {
  padding-top: 80px;
}
.inner-container {
  padding: 64px;
  background-color: white;
}
.content-container {
  display: block;
  padding: 0 120px;
}
.flex-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
}

.container-width {
  min-width: 272px;
  max-width: 500px;
}

@media only screen and (max-width: 1024px) {
  .background-image {
    background-size: contain;
  }
  .container-wrapper {
    padding: 0 140px;
  }
  .outer-container {
    padding-top: 80px;
  }
  .inner-container {
    padding: 64px;
    background-color: white;
  }
  .content-container {
    padding: 0 40px;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1600px) {
  /*   .container-width {
    max-width: 200px;
  } */
}

@media only screen and (min-width: 768px) and (max-width: 1000px) {
  .flex-container {
    display: flex;
  }
}

@media only screen and (max-width: 767px) {
  .background-image {
    background-size: contain;
  }
  .container-wrapper {
    padding: 0 24px;
  }
  .outer-container {
    padding-top: 24px;
  }
  .inner-container {
    padding: 16px;
    background-color: white;
  }
  .content-container {
    height: 100%;
    padding: 0;
  }
  .applications-header {
    font-size: 20px !important;
    margin-bottom: 40px;
  }
  .flex-container {
    align-items: start;
    padding: 16px;
    flex-direction: column;
    gap: 0px;
  }
}
#nav-links a {
  font-family: "IBM Plex Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  color: rgba(0, 0, 0, 0.87);
  text-decoration: none;
  padding: 8px 12px;
  margin-right: 32px;
}

#nav-links a.active {
  font-weight: bold;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87);
}

#nav-links a:hover {
  background-color: rgba(0, 0, 0, 0.04);
}
